import styled from '@emotion/styled/macro';

export const MainSectionTitle = styled.h2`
    font-family: ${props => props.theme.fonts.medium};
    color: rgb(34, 34, 34);
    font-size: ${props => props.theme.fontSize.xlarge};
    margin-top: ${props => props.theme.spacing.large};
    margin-bottom: ${props => props.theme.spacing.medium};
`;

export const SectionTitle = styled.h3`
    font-family: ${props => props.theme.fonts.medium};
    color: rgb(34, 34, 34);
    font-size: ${props => props.theme.fontSize.large};
    margin-top: ${props => props.theme.spacing.large};
    margin-bottom: ${props => props.theme.spacing.medium};
`;

export const ContentContainer = styled.div`
    padding: 0 ${props => props.theme.spacing.medium};

    a {
        word-wrap: break-word;
    }
`;

export const Table = styled.table`
    margin: ${props => props.theme.spacing.large} 0;

    th {
        font-family: ${props => props.theme.fonts.medium};
        border-bottom: 2px solid #ddd;
    }

    td {
        border-bottom: 1px solid #ddd;
    }
`;

export const EmphasisedText = styled.span`
    font-style: italic;
`;

export const OrderedList = styled.ol`
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;

    > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
        font-weight: bold;
    }

    > li:before {
        content: counters(item, '.') '. ';
        display: table-cell;
        padding-right: 0.6em;
        font-weight: bold;
    }

    li ol > li {
        margin: 0;
        font-weight: normal;
    }

    li ol > li:before {
        content: counters(item, '.') ' ';
        font-weight: normal;
    }

    p {
        text-decoration: underline;
        margin-top: 2px;
        font-weight: normal;
    }
`;

export const UnorderedList = styled.ul`
    list-style-type: disc;
    margin: 0;
    padding: 0;
`;

export const TermsTable = styled.div`
    display: table;
    table-layout: fixed;
    width: 100%;

    table {
        margin: ${props => props.theme.spacing.large} 0;
        display: block;
        overflow: auto;

        tr {
            text-align: center;
        }
        th {
            font-family: ${props => props.theme.fonts.medium};
            min-width: 150px;
            border: 1px solid #ddd;
            border-bottom: 2px solid #ddd;
            padding: 2px;
        }
        td {
            text-align: center;
            border: 1px solid #ddd;
            padding: 2px;
        }
    }
`;
