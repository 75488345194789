import React from 'react';
import * as Styled from '../styles';

const OctoberRollover = () => (
    <>
        <li>
            Super 6 October Rollover
            <Styled.OrderedList>
                <li>
                    Between 22:15 on the 25th of September 2024 and 15:00 on the
                    26th of October 2024, the Super 6 October Rollover will be
                    running. During this period, on Saturday Premier League
                    rounds only, if a jackpot is not won, the jackpot will roll
                    over to the next Saturday round. If the jackpot is won on
                    any of these Saturday rounds, the jackpot will return to
                    £250,000 for the following Saturday round. The dates
                    included for these rounds are listed below:
                </li>
                <li>
                    Rollover rounds:
                    <br />
                    Rollover Round 1: 22:15 25th September 2024 – 15:00 28th
                    September 2024
                    <br />
                    Rollover Round 2: 22:15 2nd October 2024 – 15:00 5th October
                    2024
                    <br />
                    Rollover Round 3: 18:45 6th October 2024 – 15:00 19th
                    October 2024
                    <br />
                    Rollover Round 4: 22:15 23rd October 2024 – 15:00 26th
                    October 2024
                </li>
                <li>
                    Rollover round jackpot details:
                    <br />
                    Please see the following table for details on the potential
                    outcomes and related Jackpot Prizes (‘W’ denotes a day where
                    the Jackpot Prize is won, ‘L’ denotes a day when it is not
                    won):
                    <Styled.TermsTable>
                        <table>
                            <tr>
                                <th>Outcome</th>
                                <th>Rollover Round 1</th>
                                <th>Rollover Round 2</th>
                                <th>Rollover Round 3</th>
                                <th>Rollover Round 4</th>
                            </tr>
                            <tr>
                                <td>L-L-L-L</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£750,000</td>
                                <td>£1,000,000</td>
                            </tr>
                            <tr>
                                <td>L-L-L-W</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£750,000</td>
                                <td>£1,000,000</td>
                            </tr>
                            <tr>
                                <td>L-L-W-W</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£750,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>L-L-W-L</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£750,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>W-L-L-L</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£750,000</td>
                            </tr>
                            <tr>
                                <td>W-L-L-W</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£750,000</td>
                            </tr>
                            <tr>
                                <td>W-L-W-L</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>W-L-W-W</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>L-W-W-L</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>L-W-L-W</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                            </tr>
                            <tr>
                                <td>L-W-L-L</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                            </tr>
                            <tr>
                                <td>L-W-W-W</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>W-W-W-W</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>W-W-W-L</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>W-W-L-L</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                            </tr>
                            <tr>
                                <td>W-W-L-W</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£250,000</td>
                                <td>£500,000</td>
                            </tr>
                        </table>
                    </Styled.TermsTable>
                </li>
                <li>
                    If for any reason the featured rounds in the rollover listed
                    above are cancelled or the jackpot is reduced, the jackpot
                    will be deemed to not have been won and will continue to
                    roll over for the next round.
                </li>
                <li>
                    Following the 26th of October 2024, rounds will adhere to
                    the standard jackpot amount of £250,000 and follow the terms
                    and conditions of the Jackpot Competition in section 6.7.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default OctoberRollover;
